@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
  .risk-doubleline-clamp-ellipsis {
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .risk-oneline-clamp-ellipsis {
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .mydashboard-guide-border {
    border: 2px dashed #ddd;
    color: #ddd;
    cursor: pointer;
    border-collapse: collapse;
  }

  .issue-detect-btn {
    width: 320px;
    height: 50px;
    background-color: red;
  }
  .default-border {
    border: 1px solid;
  }
  .default-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.4);
    border-top: 8px solid #4fdc89;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 0.8s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .text {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #4fdc89; /* 흰색에서 녹색으로 변경 */
    animation: colorChange 0.8s infinite;
  }

  @keyframes colorChange {
    0%,
    100% {
      color: #fff; /* 흰색 */
    }
    50% {
      color: #4fdc89; /* 녹색 */
    }
  }
  .speech-bubble::before {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 10px;
    border-top: 3px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 15px solid #33ca70;
    transform: rotate(270deg);
  }
}

@layer utilities {
  .z-100 {
    z-index: 100;
  }

  .pause {
    animation-play-state: paused;
    background-color: #456580;
    color: #fff;
  }
}
